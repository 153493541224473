.table-wrapper {
    width: 100%;
}

.mobile-card-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mobile-card {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 1rem;
}

.mobile-card-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    word-break: break-word;
    align-items: flex-start;
}
.mobile-card-header {
    flex-shrink: 0;
    font-weight: bold;
    margin-right: 1rem;
    white-space: nowrap;
}

.mobile-card-value {
    flex: 1;
    text-align: right;
    word-break: break-word;
    white-space: normal;
}

.no-data {
    color: #6c757d;
    font-size: 1rem;
}
.wallet-value {
    word-break: break-word;
    color: #333;
}
@media (min-width: 768px) {
    .mobile-card-list {
        display: none;
    }
}
